import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import Back from "../../components/Back";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FloatingButton from "../../components/FloatingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BasicTextFields from "../../components/BasicTextFields";
import Grid from "@mui/material/Unstable_Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import BasicDatePicker from "../../components/BasicDatePicker";
import PaidIcon from "@mui/icons-material/Paid";
import { getPaymentsByIdExternalUserAndCycle } from "../../features/payments/paymentsSlice";
import { getStudentByIdExternalUser } from "../../features/students/studentsSlice";
import HorizontalStepper from "../../components/HorizontalStepper";
import moment from "moment";
import FileInput from "../../components/FileInput";
import {
  insertPayment,
  deletePayment,
} from "../../features/payments/paymentsSlice";
import BasicAlert from "../../components/BasicAlert";
import Loader from "../../components/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

function Detail() {
  let location = useLocation();
  const path = location.pathname;
  const payments = useSelector((state) => state.payments.data);
  const students = useSelector((state) => state.students.data);
  const auth = useSelector((state) => state.auth.uuid);
  const isLoadingStudents = useSelector((state) => state.students.isLoading);
  const isLoadingPayments = useSelector((state) => state.payments.isLoading);

  const uuid = path.split("/")[3];
  const [open, setOpen] = React.useState(false);
  let totalAmount = 0;
  const currentMonth = moment().format("M");
  const cycle = moment().format("YYYY") + "-" + (currentMonth > 6 ? "B" : "A");
  const [file, setFile] = React.useState(null);
  const [xml, setXml] = React.useState(null);
  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);

  const [payment, setPayment] = useState({
    description: "",
    date: "",
    amount: 0,
    voucher: file,
    idExternalUser: uuid,
    semester: "",
    ciclo: cycle,
    xml: xml,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPayments = async () => {
      await dispatch(
        getPaymentsByIdExternalUserAndCycle({ idExternalUser: uuid, cycle })
      );
    };

    fetchPayments();
  }, [dispatch, uuid, cycle]);

  useEffect(() => {
    const fetchStudent = async () => {
      await dispatch(getStudentByIdExternalUser(uuid));
    };

    fetchStudent();
  }, [dispatch, uuid]);

  const handleClickOpen = () => {
    setFile(null);
    setXml(null);
    setDisplayBasicAlert(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPayment({
      description: "",
      date: "",
      amount: 0,
      semester: "",
      voucher: file,
      idExternalUser: uuid,
      ciclo: cycle,
      xml: xml,
    });
    setOpen(false);
  };

  const handleChangeFile = (newFile) => {
    setFile(newFile);
    setPayment({
      ...payment,
      ["voucher"]: file,
    });
  };

  const handleChangeXml = (newFile) => {
    setXml(newFile);
    setPayment({
      ...payment,
      ["xml"]: xml,
    });
  };

  const handleDeletePayment = async (idPayment) => {
    await dispatch(deletePayment({ idPayment, idUser: auth }))
      .then(async (response) => {
        // Lógica adicional después de que se complete la inserción del pago
        if (response.error) {
          setDisplayBasicAlert(true);
        } else {
          await dispatch(
            getPaymentsByIdExternalUserAndCycle({ idExternalUser: uuid, cycle })
          );
          await dispatch(getStudentByIdExternalUser(uuid));
        }
      })
      .catch((error) => {
        // Manejo de errores en caso de falla en la inserción del pago
        console.error("Error al eliminar el pago:", error);
        setDisplayBasicAlert(true);
      });
  };

  let dataPagos = [];

  const headersPagos = [
    payments.length >= 1 && payments[0].amount !== 0
      ? {
          id: 1,
          title1: "Descripción",
          title2: "Fecha",
          title3: "Cantidad",
          title4: "CFDI",
          title5: "CFDI XML",
          title6: " ",
        }
      : {
          id: 1,
          title1: "",
          title2: "Aún no existe ningún pago",
          title3: "",
          title4: "",
        },
  ];

  for (let index = 0; index < payments.length; index++) {
    if (payments[index].amount !== 0) {
      totalAmount =
        totalAmount +
        (payments[index].amount ? parseFloat(payments[index].amount) : 0);
      dataPagos.push({
        data1: { type: "text", data: payments[index].description },
        data2: { type: "text", data: payments[index].date },
        data3: { type: "text", data: "$" + payments[index].amount },
        data4: { type: "url", data: payments[index].voucher },
        data5: { type: "url", data: payments[index].xml },
        data6: {
          type: "button",
          data: (
            <IconButton
              onClick={() => handleDeletePayment(payments[index].id)}
              aria-label="add an alarm"
            >
              <DeleteIcon fontSize="medium" sx={{ color: "#ff0000 " }} />
            </IconButton>
          ),
        },
      });
    }
  }

  const steps =
    currentMonth <= 6
      ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"]
      : ["Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  let activeStep = 0;

  const item = students?.amounts
    ? students.amounts.find((obj) => obj.cycle === cycle)
    : 0;

  const monthlyPayment = item?.amount > 0 ? item.amount / 6 : 0 || 0;

  if (totalAmount === 0) {
    activeStep = 0;
  } else if (totalAmount >= monthlyPayment * 6) {
    activeStep = 6;
  } else if (totalAmount >= monthlyPayment * 5) {
    activeStep = 5;
  } else if (totalAmount >= monthlyPayment * 4) {
    activeStep = 4;
  } else if (totalAmount >= monthlyPayment * 3) {
    activeStep = 3;
  } else if (totalAmount >= monthlyPayment * 2) {
    activeStep = 2;
  } else if (totalAmount >= monthlyPayment) {
    activeStep = 1;
  } else {
    activeStep = 0;
  }

  const handleChange = (e) => {
    setPayment({
      ...payment,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDatePicker = (data) => {
    setPayment({
      ...payment,
      [data.name]: data.date,
    });
  };

  const handleInsertPayment = async () => {
    let formData = new FormData();
    formData.append("description", payment.description);
    formData.append("date", payment.date);
    formData.append("amount", payment.amount);
    formData.append("voucher", file);
    formData.append("idExternalUser", payment.idExternalUser);
    formData.append("semester", students.semester);
    formData.append("ciclo", payment.ciclo);
    formData.append("xml", xml);

    await dispatch(insertPayment(formData))
      .then(async (response) => {
        // Lógica adicional después de que se complete la inserción del pago
        if (response.error) {
          setDisplayBasicAlert(true);
        } else {
          await dispatch(
            getPaymentsByIdExternalUserAndCycle({ idExternalUser: uuid, cycle })
          );
          await dispatch(getStudentByIdExternalUser(uuid));
        }
      })
      .catch((error) => {
        // Manejo de errores en caso de falla en la inserción del pago
        console.error("Error al insertar el pago:", error);
        setDisplayBasicAlert(true);
      });

    setPayment({
      description: "",
      date: "",
      amount: 0,
      semester: "",
      voucher: file,
      idExternalUser: uuid,
      ciclo: cycle,
      xml: xml,
    });
    setOpen(false);
  };

  return (
    <Box>
      {" "}
      <Loader loading={isLoadingStudents || isLoadingPayments} />
      <Container>
        <NavBar />
        <FloatingButton onClick={handleClickOpen}></FloatingButton>
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Agregar pago</DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1, m: 4 }}>
                <Grid spacing={2}>
                  <Grid xs={12} sx={{ my: 2 }}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <DescriptionIcon />
                        </InputAdornment>
                      }
                      label="Descripción"
                      onChange={handleChange}
                      name="description"
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} sx={{ my: 2 }}>
                    <BasicDatePicker
                      name="date"
                      onChangeDatePicker={onChangeDatePicker}
                      label={"Fecha de pago"}
                    ></BasicDatePicker>
                  </Grid>
                  <Grid xs={12} sx={{ my: 2 }}>
                    <BasicTextFields
                      startAdornment={
                        <InputAdornment position="start">
                          <PaidIcon />
                        </InputAdornment>
                      }
                      label="Cantidad de pago"
                      onChange={handleChange}
                      name="amount"
                    ></BasicTextFields>
                  </Grid>
                  <Grid xs={12} sx={{ my: 2, ml: 1 }}>
                    <FileInput
                      file={file}
                      placeholder="Comprobante PDF"
                      handleChangeFile={handleChangeFile}
                      accept="application/pdf"
                    ></FileInput>
                  </Grid>
                  <Grid xs={12} sx={{ my: 2, ml: 1 }}>
                    <FileInput
                      placeholder="Comprobante XML"
                      file={xml}
                      handleChangeFile={handleChangeXml}
                      accept="application/xml"
                    ></FileInput>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleInsertPayment}
              >
                Agregar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Box sx={{ mt: -4 }}>
          <Back ruta={"/" + path.split("/")[1] + "/" + path.split("/")[2]} />
        </Box>
        <Box>
          <Box sx={{ mb: 3, textAlign: "left" }}>
            <Box sx={{ display: "flex" }}>
              <Avatar
                alt="Avatar"
                src={students.pathPhoto}
                sx={{
                  width: 200,
                  height: 200,
                  mr:"40px"
                  
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 28, md: 36 },
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                  }}
                >
                  {students.name +
                    (students.secondName
                      ? " " + students.secondName + " "
                      : " ") +
                    students.lastName1 +
                    " " +
                    students.lastName2}
                </Typography>
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt:"-10px"
                  }}
                >
                  Semestre: {students.semester}
                </Typography>
                {students.typeOfScholarship && (
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt:"-10px",
                  }}
                >
                  Tipo de beca: {students.typeOfScholarship}
                </Typography>)}
                {students.scholarshipPercentage && (
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt:"-10px"
                  }}
                >
                  Porcentaje: {students.scholarshipPercentage}%
                </Typography>)}
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt:"-10px"
                  }}
                >
                  Estado de beca: {students.scholarshipStatus}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 20, md: 24 },
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt:"-10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 20, md: 24 },
                      color: "#264496",
                    }}
                  >
                    Su estado actual es:{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, md: 24 },
                      color: students.statusPaid === 1 ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {students.statusPaid === 1
                      ? " al corriente"
                      : " pendiente pago"}
                  </Typography>
                </Typography>
              </Box>
            </Box>

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 24, md: 32 },
                
              }}
            >
              Historial de pagos
            </Typography>
            <Box sx={{ my: 4 }}>
              <HorizontalStepper activeStep={activeStep} steps={steps} />
            </Box>
          </Box>
          <Table4Rows admin={false} data={dataPagos} headers={headersPagos} />
        </Box>
        <BasicAlert
          display={displayBasicAlert}
          severity="error"
          label="Error al insertar pago"
        />
      </Container>
    </Box>
  );
}

export default Detail;
