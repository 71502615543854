import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function RecipeReviewCard({ title, description, avatar,percentage }) {
  return (
    <Card>
      <CardActionArea sx={{ height: 200 }} >
        <CardContent sx={{ textAlign: "center" }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Avatar sx={{ bgcolor: `#019645` }} aria-label="recipe">
                {avatar}
              </Avatar>
            </Grid>
            <Grid item xs={9} sx={{ textAlign: "left" }}>
              <Typography sx={{fontSize:"20px"}} variant="h5">{title}</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
