import React, { useEffect } from "react";
import CardsDropDown from "../../components/CardsDropDown";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { useLocation } from "react-router-dom";
import { getStudentByIdExternalUser } from "../../features/students/studentsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPaymentsByIdExternalUser } from "../../features/payments/paymentsSlice";
import Loader from "../../components/Loader";
import Back from "../../components/Back";

function Semestres() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const students = useSelector((state) => state.students.data);
  const payments = useSelector((state) => state.payments.data);
  const isLoadingStudents = useSelector((state) => state.students.isLoading);
  const isLoadingPayments = useSelector((state) => state.payments.isLoading);
  const uuid = path.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getStudentByIdExternalUser(uuid));
    };

    fetchData();
  }, [dispatch, uuid]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getPaymentsByIdExternalUser(uuid));
    };

    fetchData();
  }, [dispatch, uuid]);

  let semesters = [];

  function nameSemester(semester) {
    if (semester === 1) {
      return "Primer";
    } else if (semester === 2) {
      return "Segundo";
    } else if (semester === 3) {
      return "Tercer";
    } else if (semester === 4) {
      return "Cuarto";
    } else if (semester === 5) {
      return "Quinto";
    } else if (semester === 6) {
      return "Sexto";
    }
  }

  for (let index = 0; index < payments.length; index++) {
    const anio = payments[index].ciclo
      ? payments[index].ciclo.split("-")[0]
      : "";

    let agregar = true;

    for (let j = 0; j < semesters.length; j++) {
      if (semesters[j].semester === payments[index].semester) {
        agregar = false;
      }
    }

    if (agregar) {
      semesters.push({
        semester: payments[index].semester,
        nameSemester: nameSemester(payments[index].semester) + " Semestre",
        description: payments[index].ciclo
          ? payments[index].ciclo.includes("A")
            ? "Pagos de Enero " + anio + " a Junio del " + anio
            : "Pagos de Julio de " + anio + "  a Diciembre del " + anio
          : "",
        ciclo: payments[index].ciclo,
      });
    }
  }

  return (
    <Box>
      <Loader loading={isLoadingStudents && isLoadingPayments} />
      <Container>
        <NavBar />
        <Box sx={{ mt: -4 }}>
          <Back ruta={"/" + path.split("/")[1]} />
        </Box>
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 24, md: 32 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              Historial de pagos de
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 36 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              {students.name +
                (students.secondName ? " " + students.secondName : "") +
                " " +
                students.lastName1 +
                (students.lastName2 ? " " + students.lastName2 : "")}
              ,
            </Typography>
            {semesters.length === 0 ? (
              <Typography
                sx={{
                  pt: 5,
                  fontWeight: "bold",
                  fontSize: { xs: 24, md: 32 },
                  color: "red",
                }}
              >
                No hay datos disponibles
              </Typography>
            ) : null}
          </Box>
          <Grid container spacing={2}>
            {semesters.map((semester, index) => (
              <Grid
                item
                md={3}
                xs={12}
                justifyContent="center"
                alignItems="center"
                key={index}
              >
                <a onClick={() => history(path + "/" + semester.ciclo)}>
                  <CardsDropDown
                    avatar={semester.semester}
                    title={semester.nameSemester}
                    description={semester.description}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Semestres;
